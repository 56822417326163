@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.successIcon {
  @include flex-center;
  margin-bottom: 32px;
}

.confirmationText {
  margin-bottom: 48px;
}

.line {
  border: none;
  border-top: 1px solid lightgray;
  width: 100%;
  margin-top: 0;
  margin-bottom: 8px;
}

.financingContainer {
  display: flex;
  gap: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.financingSummaryText {
  margin: 16px 0;
}

.financingItem {
  display: flex;
  flex-direction: column;
}

.financeInfoContainer {
  margin-top: 18px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px;
}

.premiumFinanceConfirmation {
  background-color: rgb(245, 245, 247);
  padding: 16px 32px 32px 32px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}
