.wrapper {
  max-height: 100vh;
}

.app {
  width: 100%;
}

.container {
  padding: 8px !important;
}
// on very small screens, take drastic measures to keep everything visible
@media (max-width: 300px) {
  .container {
    align-items: flex-start !important;
  }
}

.logo {
  width: 50%;
  align-self: center;
}

.subheader {
  margin: 0px !important;
}

.form {
  padding: 16px;
  margin-bottom: 16px !important;
  background: #fff;
  border: 1px solid #e6e9eb;
}

.topForm {
  margin-top: 32px;
}

.noMargin {
  margin: 0;
  padding: 0;
}

.link {
  color: #444750;
  text-decoration: none;
}

.orderSummary {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.orderSummaryHeader {
  font-size: 14px !important;
}

.orderSummaryLineItem {
  padding-bottom: 24px;
}

.orderSummaryPaymentDescription {
  padding-top: 8px;
  padding-bottom: 8px;
}

.splitPaymentAttributes {
  padding: 16px 0 0;
  overflow-x: scroll;
}

.numericColumn {
  display: inline-block;
  width: 100%;
  text-align: right;
}

.ellipsis {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feeWrapper {
  display: flex;
  justify-content: space-between;
}

.infoIcon {
  margin-right: 8px;
}

@media (max-width: 575px) {
  .gapFix {
    // apply to <Row> to allow it to shrink below 264px
    column-gap: 0 !important;
  }

  .ellipsis {
    width: initial;
    flex: 1 1 0;
  }

  .form {
    max-width: calc(
      100vw - 8px * 2 * 2
    ); // don't allow it to be bigger than screen (and 2 containers each with 8px padding)
  }
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 24px;
  flex-wrap: wrap;
}

// This is a workaround to remove the alert header. Tried to use the #recaptcha-container on the sibling selector but it didn't work..
// Hopefully having the iframe as a selector is specific enough to target the recatpcha alert header ONLY
[class^='DialogBox_module_header']:has(+ div div div iframe) {
  display: none;
}
