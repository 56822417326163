@import '../../_variables.scss';
@import '../../styles/common.scss';
@import '@appliedsystems/applied-design-system/dist/styles/scss';

$breakpoint-md: 834px; // this breakpoint is a workaround for payment cards to align properly

@mixin flex-center {
  display: flex !important;
  align-items: center !important;
}

@mixin card-item($font-weight: normal) {
  @include flex-center;
  justify-content: space-between;
  width: 100%;
  font-weight: $font-weight;

  span {
    width: 50%;
    text-align: end;
  }
}

.card {
  height: 10rem;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 340px;
  min-width: 200px;
  border: 2px solid #d9dbe3;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    @include blue-acive-card;
  }
  &.active {
    @include blue-acive-card;
  }
  @media (max-width: $breakpoint-md) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 300px;
  }
  @include media-breakpoint-down(xs) {
    max-width: none;
  }
}

.active {
  @include blue-acive-card;
}

.title {
  @include flex-center;
  font-weight: 700;
  color: #252860;
  margin-bottom: 1.2rem;

  span {
    padding-left: 0.5rem;
    font-size: 1rem;
  }
}

.paymentMethodTooltip {
  width: auto !important;
  margin-left: auto;
}

.cardItems {
  @include flex-center;
  flex-direction: column;
  width: 95%;
}

.cardItem {
  @include card-item;
  .percentage {
    text-align: right;
  }
}

.cardItemBold {
  @include card-item(700);
}

.cardItemFeeSpan {
  margin-right: -43px;
  @media (max-width: 400px) {
    // breakpoint to fix the alignment of cc convenience fee and percentage in mobile view
    margin-right: 0;
  }
}
