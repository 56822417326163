.form {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  background: #fff;
  border: 1px solid #e6e9eb;
}

.paymentMethodTitle {
  padding-top: 16px;
}

.paymentMethodOnFileButton {
  margin-bottom: 4px;
}

.form {
  :global(.adyen-checkout__payment-method__header) {
    margin-left: -8px;
  }

  :global(.adyen-checkout__payment-method) {
    margin-bottom: 0;
    padding-bottom: 8px;
    border: none;
    background: transparent;
    border-bottom: 1px solid #e6e9eb;

    &:last-child {
      margin-top: 0;
      border-radius: 0;
      border-bottom: none;
    }
  }

  :global(.adyen-checkout__payment-method--selected) {
    background: transparent;
    border-radius: 0;
  }

  :global(.adyen-checkout__payment-method__details) {
    padding: 0;
  }

  :global(.adyen-checkout__status) {
    border: none;
  }
}
