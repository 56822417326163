.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0 auto;

  img {
    max-width: 100%;
    max-height: 200px;
  }
}
