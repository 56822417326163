/* Styles for the container of the PDF viewer */
.pdfViewer {
  min-height: 540px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.modalText {
  padding: 12px;
  gap: 12px;
  p {
    margin: 0;
  }
}

.buttonContainer {
  display: flex; /* Use Flexbox for layout */
  justify-content: flex-end; /* Align buttons to the right */
  gap: 16px; /* Space between buttons */
}
