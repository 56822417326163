.app {
  margin: 0;
  padding: 0 0 32px 0;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
