.flexTable {
  display: flex;
  flex-direction: column;

  background: #fff;
}
.flexTable thead {
  color: #585c6a;
  border-bottom: 1px solid #b0b4bf;
}
.flexTable thead tr,
.flexTable tbody tr {
  display: flex;
}
.flexTable tbody tr + tr {
  border-top: 1px solid #e6e9eb;
}
.flexTable thead tr th,
.flexTable tbody tr td {
  display: flex;
  flex: 1;
  padding: 0.5em;
}

@media screen and (max-width: 640px) {
  .flexTable {
    border: 0;
  }
  .flexTable thead {
    display: none;
  }
  .flexTable tbody tr {
    flex-direction: column;
    margin: 1em;
    border: 1px solid #ccc;
  }
  .flexTable tbody tr td {
    flex-direction: column;
  }
  .flexTable tbody tr td + td {
    border-top: 1px solid #ccc;
  }
  .flexTable tbody tr td:before {
    display: flex;
    align-items: center;
    margin: -0.5em -0.5em 0.75em -0.5em;
    padding: 0.5em;
    content: attr(data-label);
    color: #fff;
    background: #009485;
  }
}

.rowActions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.selectedRow {
  background-color: #d5e1fb !important;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: #e6e9eb;
  border: none;
  margin: 8px 0;
}

.primarySpan {
  margin-left: 20px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 2px;
}
