@import '@appliedsystems/applied-design-system/dist/styles/scss';
@import '../../variables';

.adyen-checkout {
  .adyen-checkout__payment-method {
    width: 100%;
  }

  .custom-button {
    width: fit-content;
    background: var(--brand-primary-color);
    border: 1px solid #0000;
    border-color: var(--brand-primary-border-color);
    border-radius: 3px;
    position: absolute;
    bottom: -7%;
    right: 1rem;
    padding: 5px;

    &:hover {
      filter: brightness(80%) contrast(1.5);
      background-color: var(--brand-primary-color);
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__text {
      color: var(--brand-text-color) !important;
      font-weight: bold;
    }

    &__icon {
      display: none;
    }
  }

  .adyen-checkout__payment-method__name {
    font-weight: bold;
  }

  .adyen-checkout__label {
    margin-bottom: 0.3rem;
  }

  .adyen-checkout__label__text {
    font-weight: 500;
    font-size: 0.9rem;
  }

  .adyen-checkout__label__text::after {
    content: '*';
    color: #ea0b3b;
    padding-left: 0.3rem;
  }

  .adyen-checkout__input {
    box-shadow: none;
    border-width: 2px;
    border-radius: 3px;
  }

  .adyen-checkout-form-instruction {
    display: none;
  }

  .adyen-checkout__ach .adyen-checkout__field-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    gap: 1rem;
    flex-wrap: nowrap;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap-reverse;
    }
  }

  .adyen-checkout__field.adyen-checkout__field--50.adyen-checkout__field--bankLocationId {
    margin-left: 0;
  }

  .adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
    margin-right: 0;
  }

  .adyen-checkout__field--50 {
    width: 50%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .pay__button__disabled .custom-button {
    pointer-events: none;
    filter: brightness(0.9) grayscale(0.3);
    background-color: var(--brand-primary-color);
  }

  .adyen-checkout__fieldset__title {
    display: none;
  }
}

.payment-method-radio {
  padding: 16px;
  background: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 1rem;
  width: max-content;
  padding-right: 3rem;
  margin-bottom: 1.5rem;

  @include media-breakpoint-down(md) {
    padding: 12px;
    padding-right: 2rem;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    padding: 8px;
    padding-right: 1rem;
    width: 100%;
  }
}

.back__button {
  // back button's style is adjusted to match with the adyen's pay button
  position: absolute !important; // !important is used here to override the default position (relative) in dev
  bottom: -7%;
  left: 65%;
  @media screen and (max-width: 832px) {
    left: 0;
  }
}

.adyen-checkout__card__exp-cvc .adyen-checkout__field-wrapper {
  width: fit-content;
}
