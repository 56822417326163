@import '../../variables';

$breakpoint-md: 834px; // this breakpoint is a workaround for payment cards to align properly

@mixin flex-center {
  display: flex !important;
  align-items: center !important;
}

.container {
  @include flex-center;
  justify-content: start;
  gap: 2rem !important;
  @media (max-width: $breakpoint-md) {
    flex-direction: column;
  }
}

.paymentMethodOnFileButton {
  width: fit-content;
}

.buttonsContainer {
  width: 100%;
  justify-content: end;
}

input[type='radio']:checked + label::before {
  background-color: #365ef6;
}

input[type='radio']:checked + label::after {
  background-color: #fff;
}
