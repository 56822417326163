@import '@appliedsystems/applied-design-system/dist/styles/scss';
@import '../../styles/_common.scss';

.paymentContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
  @media (max-width: var(--breakpoint-tablet)) {
    padding-right: 0;
    padding-left: 0;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  @media (max-width: 476px) {
    // this breakpoint is a workaround for aligning the buttons vertically to avoid horizontal scrolling
    flex-direction: column;
    align-items: end;
    gap: 1rem;
  }
}

.paymentMethodOnFileButton {
  width: fit-content;
  @include media-breakpoint-down(md) {
    margin-right: 0;
    margin-left: 0;
  }
}

.checkboxWrapper {
  padding: 0.75rem;
  margin-bottom: 1rem;
  @include blue-acive-card;
  border-radius: 0.3rem;
  border: 1px solid var(--border-blue);
}

.checkboxWrapper > * {
  width: 100%;
}

.error {
  width: 100%;
}

.error > * {
  width: 100%;
}
