@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.successIcon {
  @include flex-center;
  margin-bottom: 32px;
}

.printButton {
  @include flex-center;
}

@media print {
  .successIcon {
    margin-bottom: 16px;
  }
  .summaryCard {
    width: 50%;
  }
  .printButton {
    display: none;
  }
}
