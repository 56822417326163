@import '../../styles/_common.scss';
@import '../../_variables.scss';

.accordion {
  @include accordion;
}

.accordionSuccess {
  @include accordionSuccess;
}

.flexGrowOne {
  @include flexGrowOne;
}

.flexContainer {
  margin: 1rem 0 1rem 0;
}

.flexColumn {
  flex-direction: column;
  gap: 1rem;
}

.flexRow {
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}
