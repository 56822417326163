.legal {
  color: var(--ADS-Cool-Grey-900, #363840);

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
}

.methodDropdown {
  max-width: 50ch;
}

.readOnlyRadio > span {
  pointer-events: auto !important;
}
