.modalBody {
  width: 470px;
  min-height: 300px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
