@import '../variables';

body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f6fafa;
}

// workaround to remove artifact when modal window is open
.ads-theme .notification:before {
  display: none;
}

[class*='AlertMessage_module_contentBox']:has(div div.non-closeable-alert) {
  > [class*='AlertMessage_module_closeButton'] {
    display: none;
  }
}

:root {
  --brand-primary-border-color: transparent; /* Default color */
  --brand-primary-color: #365ef6; /* Default color */
  --brand-text-color: #fff; /* Default color */
  --brand-tertiary-text-color: #365ef6; /* Default color */
  --breakpoint-tablet: 1024px;
  --breakpoint-mobile: 576px;
  --breakpoint-xs: 360px;
  --background-blue: var(--ADS-Electric-Blue-50, #f1f4fd);
  --border-blue: var(--ADS-Electric-Blue-500, #365ef6);
}

button.brandPrimaryButton,
button.brandPrimaryButton:focus {
  color: var(--brand-text-color);
  background-color: var(--brand-primary-color);
  border-color: var(--brand-primary-border-color);
  &:hover {
    filter: brightness(80%) contrast(1.5);
    background-color: var(--brand-primary-color);
  }
  &:disabled {
    filter: brightness(0.9) grayscale(0.3);
    background-color: var(--brand-primary-color);
  }
  :root .ads-theme &:active {
    // adding specficity to override the ads active styles
    filter: brightness(70%) contrast(1.5);
    background-color: var(--brand-primary-color) !important;
  }
}

button.brandTertiaryButton,
button.brandTertiaryButton:focus {
  color: var(--brand-tertiary-text-color);
  background-color: transparent;
  border-color: transparent;
  &:hover,
  &:active {
    background-color: #e3eafc;
  }
}

@mixin accordion {
  margin-top: 2rem;
  @media (max-width: $breakpoint-mobile) {
    padding: 0;
  }
}

@mixin accordionSuccess {
  h2 {
    color: green;
  }
  h2::before {
    // for green tick icon (accordion validation success)
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/></svg>');
    padding-right: 10px;
  }
}

@mixin flexGrowOne {
  flex-grow: 1;
}

@mixin blue-acive-card {
  background-color: var(--background-blue);
  border-color: var(--border-blue);
}
