.container {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  height: 100vh;
  overflow-y: scroll;
  background-color: white;
  transform: scale(1.1);
  transform-origin: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.open {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.topLine {
  align-items: center;
}

.header {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  text-align: center;
}
