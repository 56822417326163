@import '../../variables';

.LayoutContainer {
  background-color: #fff;
  height: 100vh;
  padding: 8px;

  @media (max-width: $breakpoint-tablet) {
    padding: 1rem;
  }
}

.flexContainer {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;

  > div:first-child {
    flex: 1 1 0;
  }

  > div:last-child {
    flex: 0 0 330px;
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
    > div {
      width: 100%;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}
