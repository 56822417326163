@import '@appliedsystems/applied-design-system/dist/styles/scss';

.form {
  flex-direction: column;
  max-width: 400px;
  @include media-breakpoint-down(sm) {
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
  }
}
.formGroup {
  @include media-breakpoint-down(sm) {
    width: 100%;
    box-sizing: border-box;
  }
}
.fullWidth {
  width: 100%;
}
