@import '../../variables';

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

@mixin summary-card {
  background-color: rgb(245, 245, 247);
  padding: 32px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: $breakpoint-tablet) {
    width: 100%;
  }
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.confirmationSummaryCard {
  @include summary-card;
  width: 30%;
}

.summaryCard {
  @include summary-card;
  width: 100%;
}

.line {
  border-color: #fff;
}

.listItem {
  @include flex-space-between;

  span:first-of-type {
    font-weight: 500;
  }
}

.listItemSubheading {
  @include flex-space-between;
  color: #252860;
}

.invoice {
  @include flex-space-between;
  span {
    font-weight: 500;
    margin-right: 4px;
  }
}

.paidPill {
  flex: 1;
  margin-left: 8px;
}

.invoiceItem {
  @include flex-space-between;
  padding-left: 8px;
}

.description {
  @include flex-space-between;

  div:first-of-type {
    font-weight: 500;
  }
}

.paymentMethod {
  text-transform: capitalize;
}

.invoiceTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

[data-test='due-in-full-badge'] {
  background-color: #fde6dd !important;
  border-color: #fab99e !important;
  color: #d93f02 !important;
}
