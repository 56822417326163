.payBy {
  font-weight: 600;
  flex-basis: 100%;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexNoWrap {
  flex-wrap: nowrap !important;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 867px) {
    flex-direction: column;
  }
}

.flexBasis {
  flex-basis: 100%;
}

.flexGrowOne {
  flex-grow: 1;
}

.flexColumnGrow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.flexNoGrowBasis30 {
  flex-grow: 0;
  flex-basis: 30%;
}

.flexNoGrowBasis15 {
  flex-grow: 0;
  flex-basis: 15%;
}

@media (max-width: 867px) {
  .flexGrowOne,
  .flexColumnGrow,
  .flexNoGrowBasis30,
  .flexNoGrowBasis15 {
    flex-basis: 100% !important;
    width: 100% !important;
  }
}
