.table {
  flex-direction: column;
  border: none;
}

.row {
  display: flex;
  justify-content: flex-start;
}

.cell {
  flex: 1;
  text-align: center;
  margin-right: 10px;
  text-align: left;
}
