.dataGrid {
  [data-test='datagrid-actionbar'] {
    & :global(.flex-container.flex-item) {
      display: none;
    }

    & :global(.flex-container.flex-align-end) {
      flex-grow: 1;
      justify-content: space-between !important;
    }
  }
}
