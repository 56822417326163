@mixin flex-center {
  display: flex;
  justify-content: left;
  align-items: center;
}

.successIcon {
  @include flex-center;
  margin-bottom: 16px;
  margin-top: -16px;
}

.successText {
  margin-left: 8px;
  color: green;
  font-weight: bold;
}
