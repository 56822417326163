@import '../../variables';

@mixin flex-center {
  display: flex !important;
  align-items: center !important;
}

.container {
  @include flex-center;
  justify-content: start;
  gap: 2rem !important;
  @media (max-width: 834px) {
    flex-direction: column;
  }
}
