@import '@appliedsystems/applied-design-system/dist/styles/scss';

.modalBody {
  width: 400px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    box-sizing: border-box;
  }
}
