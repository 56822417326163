@import '@appliedsystems/applied-design-system/dist/styles/scss';

.flexContainer {
  padding: 0;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 3rem;
  @include media-breakpoint-down(xs) {
    padding-top: 1.5rem;
    gap: 1rem;
  }
}
.titleWrapper {
  flex-direction: column;
  gap: 20px;
  h2 {
    line-height: 2rem;
  }
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  @include media-breakpoint-down(xs) {
    gap: 10px;

    h2 {
      line-height: 1.5rem;
    }
  }
}

.buttonIcon {
  margin-right: 10px;

  path {
    stroke: #fff;
  }
}
