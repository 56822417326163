@import '@appliedsystems/applied-design-system/dist/styles/scss';

.disclaimer {
  text-align: justify;
  hyphens: auto;
  line-height: 1.5;
  padding: 0;
  @include media-breakpoint-down(lg) {
    margin-bottom: 2rem;
  }
}
